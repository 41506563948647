var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Step__form",attrs:{"data-test-id":"company_signup_form-step_2"}},[_c('div',[_c('ui-validate',{on:{"status":({ detail }) => _vm.formStatus = detail}},[_c('h1',{staticClass:"mb-5",attrs:{"data-test-id":"title-text"}},[_vm._v(" "+_vm._s(_vm.$t('refactor.company_signup.step_2.title'))+" ")]),_c('div',{staticClass:"mb-4"},[_c('ui-text-input',{directives:[{name:"validate",rawName:"v-validate.blurinput.input",value:({
            isEmail: {
              message: _vm.$t('email_input.invalid_email'),
            },
            isRequired: {
              message: _vm.$t('refactor.company_signup.errors.mandatory'),
              whiteSpaceMessage: _vm.$t('refactor.company_signup.errors.mandatory'),
            },
            emailValidator: _vm.emailValidator
          }),expression:"{\n            isEmail: {\n              message: $t('email_input.invalid_email'),\n            },\n            isRequired: {\n              message: $t('refactor.company_signup.errors.mandatory'),\n              whiteSpaceMessage: $t('refactor.company_signup.errors.mandatory'),\n            },\n            emailValidator\n          }",modifiers:{"blurinput":true,"input":true}}],ref:_vm.STEP_2_REFS.companyEmail,staticClass:"d-block",attrs:{"name":_vm.STEP_2_REFS.companyEmail,"value":_vm.data.companyEmail,"label":_vm.$t('refactor.company_signup.fields.admin_email'),"placeholder":_vm.$t('refactor.company_signup.fields.admin_email_placeholder'),"type":_vm.INPUT_TYPES.email,"data-test-id":"company_admin_email-input_text"},on:{"changevalue":({ detail }) => _vm.companyEmail(detail),"focusinput":_vm.resetInputValidation}}),_c('div',{staticClass:"emobg-font-small pl-1 emobg-color-ink-light pt-1",attrs:{"data-test-id":"email-caption_text"}},[_vm._v(" "+_vm._s(_vm.$t('refactor.company_signup.fields.admin_email_bottom_text'))+" ")])],1),_c('div',{staticClass:"mb-4"},[_c('ui-text-input',{directives:[{name:"validate",rawName:"v-validate.blurinput.input",value:({
            isRequired: {
              message: _vm.$t('refactor.company_signup.errors.mandatory'),
            },
            arePasswordRequirementsMet: () => ({
              isValid: _vm.isPasswordValid,
              message: ' ',
            }),
          }),expression:"{\n            isRequired: {\n              message: $t('refactor.company_signup.errors.mandatory'),\n            },\n            arePasswordRequirementsMet: () => ({\n              isValid: isPasswordValid,\n              message: ' ',\n            }),\n          }",modifiers:{"blurinput":true,"input":true}}],ref:_vm.STEP_2_REFS.password,staticClass:"d-block",attrs:{"name":_vm.STEP_2_REFS.password,"value":_vm.data.password,"label":_vm.$t('refactor.company_signup.fields.password'),"type":_vm.isPasswordRevealed ? _vm.INPUT_TYPES.text: _vm.INPUT_TYPES.password,"icon-left":_vm.isPasswordRevealed ? _vm.ICONS.hide : _vm.ICONS.show,"data-test-id":"company_admin_password-input_text","reverse":""},on:{"clickicon":function($event){_vm.isPasswordRevealed = !_vm.isPasswordRevealed},"changevalue":({ detail }) => _vm.password(detail),"focusinput":_vm.onFocusPassword}}),_c('Transition',{attrs:{"name":"fade"}},[(_vm.showPasswordRequirements)?_c('PasswordFeedBackComponent',{attrs:{"password-requirements":_vm.PASSWORD_VALIDATIONS,"password":_vm.data.password},on:{"onPasswordChange":_vm.validatePassword}}):_vm._e()],1)],1),_c('ui-select',{directives:[{name:"validate",rawName:"v-validate.select",value:({
          isRequired: {
            message: _vm.$t('refactor.company_signup.errors.mandatory'),
          },
        }),expression:"{\n          isRequired: {\n            message: $t('refactor.company_signup.errors.mandatory'),\n          },\n        }",modifiers:{"select":true}}],ref:_vm.STEP_2_REFS.title,staticClass:"d-block mb-4",attrs:{"name":_vm.STEP_2_REFS.title,"label":_vm.$t('refactor.company_signup.fields.title'),"placeholder":_vm.$t('refactor.company_signup.fields.title_placeholder'),"value":_vm.data.title,"data-test-id":"company_admin_title-select"},domProps:{"options":_vm.titlesOptions},on:{"selectoption":({ detail }) => _vm.title(detail)}}),_c('ui-text-input',{directives:[{name:"validate",rawName:"v-validate.blurinput",value:({
          isRequired: {
            message: _vm.$t('refactor.company_signup.errors.mandatory'),
            whiteSpaceMessage: _vm.$t('refactor.company_signup.errors.mandatory'),
          },
        }),expression:"{\n          isRequired: {\n            message: $t('refactor.company_signup.errors.mandatory'),\n            whiteSpaceMessage: $t('refactor.company_signup.errors.mandatory'),\n          },\n        }",modifiers:{"blurinput":true}}],ref:_vm.STEP_2_REFS.firstName,staticClass:"d-block mb-4",attrs:{"name":_vm.STEP_2_REFS.firstName,"label":_vm.$t('refactor.company_signup.fields.first_name'),"value":_vm.data.firstName,"data-test-id":"company_admin_title-input_text"},on:{"changevalue":({ detail }) => _vm.firstName(detail),"focusinput":_vm.resetInputValidation}}),_c('ui-text-input',{directives:[{name:"validate",rawName:"v-validate.blurinput",value:({
          isRequired: {
            message: _vm.$t('refactor.company_signup.errors.mandatory'),
            whiteSpaceMessage: _vm.$t('refactor.company_signup.errors.mandatory'),
          },
        }),expression:"{\n          isRequired: {\n            message: $t('refactor.company_signup.errors.mandatory'),\n            whiteSpaceMessage: $t('refactor.company_signup.errors.mandatory'),\n          },\n        }",modifiers:{"blurinput":true}}],ref:_vm.STEP_2_REFS.lastName,staticClass:"d-block mb-4",attrs:{"name":_vm.STEP_2_REFS.lastName,"label":_vm.$t('refactor.company_signup.fields.last_name'),"value":_vm.data.lastName,"data-test-id":"company_admin_last_name-input_text"},on:{"changevalue":({ detail }) => _vm.lastName(detail),"focusinput":_vm.resetInputValidation}}),_c('ui-datetimepicker',{directives:[{name:"validate",rawName:"v-validate.blurinput",value:({
          isRequired: {
            message: _vm.$t('refactor.company_signup.errors.mandatory'),
            whiteSpaceMessage: _vm.$t('refactor.company_signup.errors.mandatory'),
          },
          isValidDate: _vm.isValidDate,
        }),expression:"{\n          isRequired: {\n            message: $t('refactor.company_signup.errors.mandatory'),\n            whiteSpaceMessage: $t('refactor.company_signup.errors.mandatory'),\n          },\n          isValidDate,\n        }",modifiers:{"blurinput":true}}],ref:_vm.STEP_2_REFS.birthDate,staticClass:"d-block mb-4",attrs:{"label":_vm.$t('birthdate_input.label'),"locale":_vm.locale,"name":_vm.STEP_2_REFS.birthDate,"data-test-id":"company_admin_birthday-datepicker","skiptime":"","external-validation":""},domProps:{"date":_vm.data.birthDate ? _vm.moment(_vm.data.birthDate) : null,"range":{
          start: _vm.maxAge,
          end: _vm.minAge,
        }},on:{"datechanged":({ detail }) => _vm.setBirthDate(detail),"focusinput":_vm.resetInputValidation}}),_c('PhoneNumberWrapper',{staticClass:"mb-4",attrs:{"label":_vm.$t('refactor.company_signup.fields.phone'),"country":_vm.serviceCountryCode || _vm.COUNTRIES_ISO_CODES.spain,"value":_vm.data.phoneNumber,"blur-validation":"","data-test-id":"company_admin_phone-input_text"},on:{"change":_vm.phoneNumber,"isValid":isValid => _vm.isPhoneNumberValid = isValid}}),_c('StepDocumentUploader',{directives:[{name:"show",rawName:"v-show",value:(_vm.documentsConfig),expression:"documentsConfig"}],staticClass:"mb-4",attrs:{"title":_vm.$t('refactor.company_signup.extra_documents.title'),"subtitle":_vm.$t('refactor.company_signup.extra_documents.subtitle'),"config":_vm.documentsConfig,"data":_vm.data,"data-test-id":"extra_documents-uploader_wrapper"}}),_c('ui-button',_vm._b({staticClass:"d-block mb-4",attrs:{"size":_vm.SIZES.large,"disabled":!_vm.isFormValid,"type":_vm.BUTTON_TYPES.submit,"loading":_vm.isLoading,"data-test-id":"submit-button"},on:{"clickbutton":_vm.handlerFormSubmit}},'ui-button',_vm.fetchButtonSpecs(),false),[_vm._v(" "+_vm._s(_vm.$t('refactor.company_signup.actions.next'))+" ")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }