<template>
  <div
    class="Step__form"
    data-test-id="company_signup_form-step_2"
  >
    <div>
      <ui-validate @status="({ detail }) => formStatus = detail">
        <h1
          data-test-id="title-text"
          class="mb-5"
        >
          {{ $t('refactor.company_signup.step_2.title') }}
        </h1>

        <div class="mb-4">
          <ui-text-input
            :ref="STEP_2_REFS.companyEmail"
            v-validate.blurinput.input="{
              isEmail: {
                message: $t('email_input.invalid_email'),
              },
              isRequired: {
                message: $t('refactor.company_signup.errors.mandatory'),
                whiteSpaceMessage: $t('refactor.company_signup.errors.mandatory'),
              },
              emailValidator
            }"
            :name="STEP_2_REFS.companyEmail"
            :value="data.companyEmail"
            :label="$t('refactor.company_signup.fields.admin_email')"
            :placeholder="$t('refactor.company_signup.fields.admin_email_placeholder')"
            :type="INPUT_TYPES.email"
            data-test-id="company_admin_email-input_text"
            class="d-block"
            @changevalue="({ detail }) => companyEmail(detail)"
            @focusinput="resetInputValidation"
          />
          <div
            data-test-id="email-caption_text"
            class="emobg-font-small pl-1 emobg-color-ink-light pt-1"
          >
            {{ $t('refactor.company_signup.fields.admin_email_bottom_text') }}
          </div>
        </div>

        <div class="mb-4">
          <ui-text-input
            :ref="STEP_2_REFS.password"
            v-validate.blurinput.input="{
              isRequired: {
                message: $t('refactor.company_signup.errors.mandatory'),
              },
              arePasswordRequirementsMet: () => ({
                isValid: isPasswordValid,
                message: ' ',
              }),
            }"
            :name="STEP_2_REFS.password"
            :value="data.password"
            :label="$t('refactor.company_signup.fields.password')"
            :type="isPasswordRevealed ? INPUT_TYPES.text: INPUT_TYPES.password"
            :icon-left="isPasswordRevealed ? ICONS.hide : ICONS.show"
            data-test-id="company_admin_password-input_text"
            class="d-block"
            reverse
            @clickicon="isPasswordRevealed = !isPasswordRevealed"
            @changevalue="({ detail }) => password(detail)"
            @focusinput="onFocusPassword"
          />
          <Transition name="fade">
            <PasswordFeedBackComponent
              v-if="showPasswordRequirements"
              :password-requirements="PASSWORD_VALIDATIONS"
              :password="data.password"
              @onPasswordChange="validatePassword"
            />
          </Transition>
        </div>

        <ui-select
          :ref="STEP_2_REFS.title"
          v-validate.select="{
            isRequired: {
              message: $t('refactor.company_signup.errors.mandatory'),
            },
          }"
          :name="STEP_2_REFS.title"
          :label="$t('refactor.company_signup.fields.title')"
          :placeholder="$t('refactor.company_signup.fields.title_placeholder')"
          :options.prop="titlesOptions"
          :value="data.title"
          data-test-id="company_admin_title-select"
          class="d-block mb-4"
          @selectoption="({ detail }) => title(detail)"
        />

        <ui-text-input
          :ref="STEP_2_REFS.firstName"
          v-validate.blurinput="{
            isRequired: {
              message: $t('refactor.company_signup.errors.mandatory'),
              whiteSpaceMessage: $t('refactor.company_signup.errors.mandatory'),
            },
          }"
          :name="STEP_2_REFS.firstName"
          :label="$t('refactor.company_signup.fields.first_name')"
          :value="data.firstName"
          data-test-id="company_admin_title-input_text"
          class="d-block mb-4"
          @changevalue="({ detail }) => firstName(detail)"
          @focusinput="resetInputValidation"
        />

        <ui-text-input
          :ref="STEP_2_REFS.lastName"
          v-validate.blurinput="{
            isRequired: {
              message: $t('refactor.company_signup.errors.mandatory'),
              whiteSpaceMessage: $t('refactor.company_signup.errors.mandatory'),
            },
          }"
          :name="STEP_2_REFS.lastName"
          :label="$t('refactor.company_signup.fields.last_name')"
          :value="data.lastName"
          data-test-id="company_admin_last_name-input_text"
          class="d-block mb-4"
          @changevalue="({ detail }) => lastName(detail)"
          @focusinput="resetInputValidation"
        />

        <ui-datetimepicker
          :ref="STEP_2_REFS.birthDate"
          v-validate.blurinput="{
            isRequired: {
              message: $t('refactor.company_signup.errors.mandatory'),
              whiteSpaceMessage: $t('refactor.company_signup.errors.mandatory'),
            },
            isValidDate,
          }"
          :date.prop="data.birthDate ? moment(data.birthDate) : null"
          :label="$t('birthdate_input.label')"
          :locale="locale"
          :name="STEP_2_REFS.birthDate"
          :range.prop="{
            start: maxAge,
            end: minAge,
          }"
          data-test-id="company_admin_birthday-datepicker"
          class="d-block mb-4"
          skiptime
          external-validation
          @datechanged="({ detail }) => setBirthDate(detail)"
          @focusinput="resetInputValidation"
        />

        <PhoneNumberWrapper
          :label="$t('refactor.company_signup.fields.phone')"
          :country="serviceCountryCode || COUNTRIES_ISO_CODES.spain"
          :value="data.phoneNumber"
          blur-validation
          data-test-id="company_admin_phone-input_text"
          class="mb-4"
          @change="phoneNumber"
          @isValid="isValid => isPhoneNumberValid = isValid"
        />

        <StepDocumentUploader
          v-show="documentsConfig"
          :title="$t('refactor.company_signup.extra_documents.title')"
          :subtitle="$t('refactor.company_signup.extra_documents.subtitle')"
          :config="documentsConfig"
          :data="data"
          data-test-id="extra_documents-uploader_wrapper"
          class="mb-4"
        />

        <ui-button
          v-bind="fetchButtonSpecs()"
          :size="SIZES.large"
          :disabled="!isFormValid"
          :type="BUTTON_TYPES.submit"
          :loading="isLoading"
          data-test-id="submit-button"
          class="d-block mb-4"
          @clickbutton="handlerFormSubmit"
        >
          {{ $t('refactor.company_signup.actions.next') }}
        </ui-button>
      </ui-validate>
    </div>
  </div>
</template>

<script>
import {
  COUNTRIES_ISO_CODES,
  FILE_TYPES,
  isObjectEmpty,
  ITALY_COMPANY_VALIDATION_PATTERN,
  navigationErrorHandler,
} from '@emobg/web-utils';
import get from 'lodash/get';
import round from 'lodash/round';
import includes from 'lodash/includes';
import map from 'lodash/map';
import every from 'lodash/every';
import moment from 'moment';
import { mapActions, mapMutations, mapState } from 'vuex';
import { Validate, VALIDATION_EVENT_MODIFIERS } from '@emobg/vue-base';

import { useMaps } from '@/composable/App/Map/useMaps';
import SegmentMixin from '@/mixins/Segment';
import { SEGMENT_EVENTS } from '@/vue/constants';
import { passwordRegex } from '@/constants/regularExpression';
import { STORE_STEPS } from '@/constants/signUpSteps';
import { DATE_UNITS } from '@/constants/dates';
import PhoneNumberWrapper from '@/components/PhoneNumberWrapper/PhoneNumberWrapper';
import { useTheme } from '@/composable/Theme/useTheme';

import { PASSWORD_VALIDATION } from '@/domains/Account/SignUp/User/constants/PasswordFeedBackValidation.const';
import COMPANY_SIGN_UP_ROUTES from '../../router/routes-names';
import { getIdPassportConfig } from '../constants/IdPassportConfig';
import { validateRefs } from '../../helpers';
import { COMPANY_MODULE, GENERIC_MODULE } from '../../constants/modules';
import { ITALY_CERTIFICATE_MAX_SIZE } from '../constants/ItalyCertificate';
import { MB_IN_BYTES, SIGNUP_AGE, STEP_2_REFS } from '../constants/general';
import StepDocumentUploader from '../../../components/StepDocumentUploader';
import { resetInputValidation } from '../../../utils/validation.util';
import PasswordFeedBackComponent from '../../User/components/PasswordFeedBackComponent';

export default {
  name: 'Step2',
  components: {
    PhoneNumberWrapper,
    StepDocumentUploader,
    PasswordFeedBackComponent,
  },
  directives: {
    Validate,
  },
  mixins: [
    SegmentMixin,
  ],
  setup() {
    const { googleMapsAutocompleteService, googleMapsGeocoderService } = useMaps();
    const { fetchButtonSpecs } = useTheme();
    return { googleMapsAutocompleteService, googleMapsGeocoderService, fetchButtonSpecs };
  },
  data() {
    return {
      formStatus: {},
      isPasswordRevealed: false,
      invalidEmails: [],
      documentsConfig: [],
      isPhoneNumberValid: true,
      isLoading: false,
      showPasswordRequirements: false,
      isPasswordValid: false,
      maxAge: null,
      minAge: null,
    };
  },
  computed: {
    ...mapState(COMPANY_MODULE, {
      done: state => state.done,
      data: state => state.data,
      info: state => state.info,
      titlesOptions: state => map(state.titles, ({ name, internalName }) => ({ label: name, value: internalName })),
      serviceCountryCode: state => get(state, 'data.serviceCountryCode'),
    }),
    ...mapState(GENERIC_MODULE, {
      locale: state => state.locale,
      titlesOptions: state => map(state.titles, ({ name, internalName }) => ({ label: name, value: internalName })),
    }),
    isItalianCompany() {
      return this.serviceCountryCode === COUNTRIES_ISO_CODES.italy;
    },
    isFormValid() {
      return every([
        this.data.companyEmail && this.isEmailValid,
        this.data.password,
        this.data.title,
        this.data.firstName,
        this.data.lastName,
        this.data.birthDate,
        this.data.phoneNumber && this.isPhoneNumberValid,
        this.areDocumentsValid,
        this.formStatus.isValid,
        this.isPasswordValid,
      ], Boolean);
    },
    areDocumentsValid() {
      return !this.isItalianCompany || !!((this.data.extraDocuments.italianIdFront
        && this.data.extraDocuments.italianIdBack) || this.data.extraDocuments.italianPassport);
    },
    isEmailValid() {
      return !includes(this.invalidEmails, this.data.companyEmail);
    },
  },
  created() {
    this.STEP_2_REFS = STEP_2_REFS;
    this.maxAge = moment().subtract(SIGNUP_AGE.max, DATE_UNITS.years);
    this.minAge = moment().subtract(SIGNUP_AGE.min, DATE_UNITS.years);
    this.COUNTRIES_ISO_CODES = COUNTRIES_ISO_CODES;
    this.FILE_TYPES = FILE_TYPES;
    this.ITALY_CERTIFICATE_MAX_SIZE = ITALY_CERTIFICATE_MAX_SIZE;
    this.ITALY_COMPANY_VALIDATION_PATTERN = ITALY_COMPANY_VALIDATION_PATTERN;
    this.passwordRegex = passwordRegex;
    this.italianCertificateMaxSize = round(ITALY_CERTIFICATE_MAX_SIZE / MB_IN_BYTES);
    this.PASSWORD_VALIDATIONS = PASSWORD_VALIDATION;
  },
  beforeMount() {
    // TODO [ROAD-213]: Carsharing Matters into Phoenix FR Migration
    const COUNTRY_PARAMS = {
      [COUNTRIES_ISO_CODES.italy]: [
        COUNTRIES_ISO_CODES.italy,
        this.italyIdFront,
        this.italyIdBack,
        this.italyPassport,
      ],
    };

    this.documentsConfig = COUNTRY_PARAMS[this.serviceCountryCode]
      ? getIdPassportConfig(...COUNTRY_PARAMS[this.serviceCountryCode])
      : undefined;
  },
  async mounted() {
    const query = get(this, '$route.query', {});
    if (!this.done.step1) {
      this.$router.push({ name: COMPANY_SIGN_UP_ROUTES.step1, query }).catch(navigationErrorHandler);
    }
    validateRefs(STEP_2_REFS, this.$refs, this.data);
    this.trackSegment({
      name: SEGMENT_EVENTS.B2B_STEP_2,
      cache: true,
    });
    const emailElement = get(this, `$refs.${STEP_2_REFS.companyEmail}`);
    const passwordElement = get(this, `$refs.${STEP_2_REFS.password}`);
    if (!isObjectEmpty(emailElement.value) || !isObjectEmpty(passwordElement.value)) {
      emailElement.dispatchEvent(new Event(VALIDATION_EVENT_MODIFIERS.validate));
      passwordElement.dispatchEvent(new Event(VALIDATION_EVENT_MODIFIERS.validate));
      passwordElement.dispatchEvent(new Event(VALIDATION_EVENT_MODIFIERS.focusInput));
    }
  },
  methods: {
    get,
    resetInputValidation,
    ...mapActions(COMPANY_MODULE, [
      'checkEmail',
    ]),
    emailValidator() {
      return {
        message: this.$t('refactor.company_signup.errors.duplicated_email'),
        isValid: this.isEmailValid,
      };
    },
    ...mapMutations(COMPANY_MODULE, [
      'doneStep',
      'undoStep',
      'updateStep',
      'companyEmail',
      'password',
      'title',
      'firstName',
      'lastName',
      'birthDate',
      'phoneNumber',
      'italyPassport',
      'italyIdFront',
      'italyIdBack',
    ]),
    async handlerFormSubmit() {
      this.isLoading = true;
      await this.checkEmail();
      if (!this.info.isEmailValid) {
        this.invalidEmails.push(this.data.companyEmail);
        const emailRef = get(this.$refs, STEP_2_REFS.companyEmail);
        if (emailRef) {
          emailRef.dispatchEvent(new Event(VALIDATION_EVENT_MODIFIERS.validate));
        }
      }
      if (this.isFormValid) {
        this.doneStep(STORE_STEPS.two);
        this.updateStep(STORE_STEPS.three);
        this.$router.push({ name: COMPANY_SIGN_UP_ROUTES.step3, query: get(this.$route, 'query', {}) }).catch(navigationErrorHandler);
      } else {
        this.undoStep(STORE_STEPS.two);
        this.updateStep(STORE_STEPS.two);
      }
      this.isLoading = false;
    },
    setBirthDate(newBirthdate) {
      const convertedNewBirthdate = moment(newBirthdate);

      this.birthDate(
        convertedNewBirthdate && convertedNewBirthdate.isValid()
          ? convertedNewBirthdate.toDate()
          : newBirthdate,
      );
    },
    isValidDate() {
      return {
        isValid: moment(this.data.birthDate).isValid(),
        message: this.$t('refactor.company_signup.errors.invalid_date'),
      };
    },
    onFocusPassword(event) {
      if (this.isPasswordValid) {
        this.resetInputValidation(event);
      }
      this.showPasswordRequirements = true;
    },
    onFocusEmail(event) {
      if (this.isEmailValid) {
        this.resetInputValidation(event);
      }
    },
    validatePassword(isPasswordValid) {
      this.isPasswordValid = isPasswordValid;
    },
  },
};
</script>
